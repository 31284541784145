// Admin Layout (AVIO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: AVIO
// Copyright 2022 WrrapTheme

.daterangepicker{
    color: #000;
    td.active,
    td.active:hover,
    .ranges li.active{
        background-color: var(--primary-color);
    }
    td.in-range{
        background-color: rgba(var(--primary-rgb),.1);
    }
}