// Admin Layout (AVIO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: AVIO
// Copyright 2022 WrrapTheme

.inbox{
    .menu-list{
        .m-link{
            color: var(--color-500);
            display: flex;
            align-items: center;
            padding: 6px;

            &:hover{
                color: var(--primary-color);
            }
            i.fa{
                width: 26px;
            }
        }
    }
    .fa-star{
        color: var(--color-300);
        &:hover,
        &.active{
            color: var(--primary-color);
        }
    }

    .order-1{
        background: var(--card-color);
        min-width: 210px;
        padding: 14px;
        border-radius: 1rem;
    }

    .order-2{

        .list-group{
            li{
                &:first-child{
                    border-radius: .75rem .75rem 0 0;
                }
                &:last-child{
                    border-radius: 0 0 .75rem .75rem;
                }
                &:hover{
                    .hover-actions{
                        display: flex;
                        justify-content: space-between;
                    }    
                }
                .hover-actions{
                    position: absolute;
                    text-align: end;
                    display: none;
                    width: 135px;
                    right: 0;
                }
            }
        }
    }

    @media only screen and (max-width: $break-medium) {
        .order-1{
            @include transition(ease .1s);
            position: fixed;
            box-shadow: 5px 1rem 1rem rgba($dark,.1);
            left: -240px;
            top: 69px;
            border-radius: 0;
            height: calc(100vh - 69px);
            
            &.open{
                left: 0;
                z-index: 99;
            }
        }
    }
    @media only screen and (max-width: $break-small) {
        .order-1{
            top: 0;
            height: calc(100vh - 10px);
        }
    }
}