// Admin Layout (AVIO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: AVIO
// Copyright 2022 WrrapTheme

.avio{
    display: flex;
    flex-direction: column;

    .body-header,
    .page-search,
    .page-header,
    .page-body,
    .page-footer{

        @media only screen and (min-width: $break-1024) {
            padding: 0 1rem;
        }

        @media only screen and (min-width: $break-xlarge) {
            padding: 0 5rem;
        }

        @media only screen and (min-width: $break-xlarge + 180px) {
            padding: 0 11rem;
        }
    }

    // main top bar menu
    .body-header{
        background-color: var(--card-color);
        display: flex;
        align-items: center;
        // height: 100px;
        height: 70px;
        -webkit-transition: height .3s;
        transition: height .3s;
        z-index: 1021;

        @media only screen and (min-width: $break-large) {
            &.scrolled-nav{
                height: 70px !important;
            }
        }

        .menu-link{
            display: flex;
            align-items: center;

            .dropdown{
                margin: 0 .3rem;
                
                // mobile bottom fix menu css
                @media only screen and (min-width: $break-medium) {
                    &.active,
                    &:hover{
                        position: relative;

                        &::before{
                            position: absolute;
                            background: var(--primary-color);
                            content: '';
                            top: -19px;
                            left: 0;
                            width: 100%;
                            height: 5px;
                        }
                    }
                }

                @media only screen and (min-width: $break-xlarge) {
                    margin: 0 .7rem;
                }
                > .dropdown-menu{
                    transform: translate(0px, 50px) !important;
                }
            }

            .btn-link{
                color: var(--color-800);
                text-decoration: none;
                display: flex;
                align-items: center;
                line-height: 17px;

                &:focus{
                    outline: none;
                    box-shadow: none;
                }

                @media only screen and (min-width: $break-1280) {
                    font-size: 17px;
                }
            }

            // mobile bottom fix menu css
            @media only screen and (max-width: $break-medium) {
                background-color: var(--card-color);
                border-top: 2px solid var(--primary-color);
                position: fixed;
                display: flex;
                justify-content: center;
                left: 0;
                bottom: 0;
                width: 100%;
                z-index: 999;

                .dropdown{
                    margin: 0;
                    width: 25%;
                }
                .btn-link{
                    flex-direction: column;

                    &.show{
                        background: rgba(var(--primary-rgb),.15);
                        border-radius: 0;
                    }

                    &:after{
                        display: none;
                    }
                }

                .menu-dashboard,
                .menu-pages,
                .menu-resources,
                .menu-apps{
                    > .dropdown-menu{
                        @include c_overflow(scroll);
                        position: fixed !important;
                        inset: auto auto 0px 0px;
                        margin: 0px;
                        transform: translate3d(0px, 0, 0px) !important;
                        top: 0 !important;
                        left: 0;
                        width: 100%;
                        height: calc(100vh - 50px);
                        border-radius: 0 !important;
                    }
                }

                .menu-dashboard .dropdown-menu{
                    ul{
                        display: flex;
                        flex-wrap: wrap;
                        align-content: center;
                        padding: 10px;

                        li{
                            background: var(--primary-color);
                            text-align: center;
                            align-items: center;
                            display: flex;
                            border-radius: 1rem;
                            width: calc(50% - 10px);
                            height: 80px;
                            margin: 5px;

                            .dropdown-item{
                                color: $white;
                                white-space: normal;
                            }
                        }
                    }
                }
                .menu-pages .dropdown-menu{
                    .dropdown-submenu {
                        z-index: 9;
                        ul{
                            background: var(--body-color);
                            display: block;
                            box-shadow: none !important;
                            position: relative !important;
                            height: auto;
                            margin-bottom: 1rem;
                            left: 0 !important;
                            top: 100% !important;
                        }
                    }
                }
            }
            .menu-resources{
                > .dropdown-menu{
                    @include c_overflow(scroll);
                }
            }
        }

        .menu-pages{
            > .dropdown-menu{
                min-width: 210px;
            }
        }   
    }

    //main search 
    .page-search{
        background-color: var(--secondary-color);
        color: $white;
        position: relative;
        z-index: 999;

        .main-search{
            padding: 0 !important;
            .search-result{
                width: 100%;

                // mobile bottom fix menu css
                @media only screen and (max-width: $break-small) {
                    margin: 0;
                    top: 140px;
                }
            }
        }
        .form-control{
            background-color: transparent;
            padding: 0;
            border: 0;
            font-size: 1.2rem;

            &::placeholder{
                color: rgba($white, 0.8);
            }
            &:focus::placeholder{
                color: $white;
            }
        }
    }

    // page header and breadcrumb
    .page-header{
        background-color: var(--primary-color);
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        .breadcrumb{
            background-color: transparent;
            li::before,
            a{
                color: $white;
                opacity: .5;
            }
            li.active{
                color: var(--secondary-color);
            }
        }
        .page-action{
            .btn{
                background: rgba($white, 0.1);
                color: $white;
                display: inline-flex;
                align-items: center;
                height: 34px;
            }
        }
    }

    // 
    .page-body{
        position: relative;
        &::after{
            background-color: var(--primary-color);
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100px;
            z-index: -1;
        }
        // body layout version
        &.page-layout-1{
            .menu-list{
                .m-link{
                    color: var(--color-500);
                    display: flex;
                    align-items: center;
                    padding: 6px;
                    &:hover{
                        color: var(--primary-color);
                    }
                    i.fa{
                        width: 26px;
                    }
                }
            }
            .order-1{
                background: var(--card-color);
                min-width: 230px;
                max-width: 230px;
                padding: 14px 18px;
                border-radius: 1rem;
                font-size: 14px;

                @media only screen and (min-width: $break-medium) {
                    top: 85px;
                }
            }
            .order-2{
                width: 100%;
                .list-group{
                    li{
                        &:first-child{
                            border-radius: .75rem .75rem 0 0;
                        }
                        &:last-child{
                            border-radius: 0 0 .75rem .75rem;
                        }
                        &:hover{
                            .hover-actions{
                                display: block;
                            }    
                        }
                        .hover-actions{
                            position: absolute;
                            text-align: end;
                            display: none;
                            width: 135px;
                            right: 0;
                        }
                    }
                }
            }
        
            @media only screen and (max-width: $break-medium) {
                .order-1{
                    @include transition(ease .1s);
                    position: fixed;
                    box-shadow: 5px 1rem 1rem rgba($dark,.1);
                    left: -400px;
                    top: 69px;
                    border-radius: 0;
                    height: calc(100vh - 69px);

                    &.open{
                        left: 0;
                        z-index: 99;
                    }
                }
            }
            @media only screen and (max-width: $break-small) {
                .order-1{
                    top: 0;
                    height: calc(100vh - 10px);
                }
            }
        }
    }

    // mobile bottom fix menu css
    @media only screen and (max-width: $break-medium) {
        margin-bottom: 51px;
    }
}

// mega dropdown
.mega-dropdown{
    width: 100%;

    ul li{
        &.active,
        &:hover {
            a{
                color: var(--primary-color);
            }
        }
    }

    &.fullwidth{
        color: var(--color-600);
        width: 100%;
        position: fixed !important;
        inset: 20px auto auto 0px !important;

        @media only screen and (max-width: $break-1024) {
            height: calc(100vh - 70px);
        }
        @media only screen and (min-width: $break-1024) {
            padding: 1rem !important;
        }
        @media only screen and (min-width: $break-xlarge) {
            padding: 3rem 5rem !important;
        }
        @media only screen and (min-width: $break-xlarge + 180px) {
            padding: 3rem 11rem !important;
        }
    }
}

// page header tabbar
.ph-tab { 
    width: 100%;

    > input,
    section > div {
        display: none;
        min-height: 192px;

        @media only screen and (max-width: $break-xsmall) {
            min-height: auto;
        }
    }

    #tab1:checked ~ section .tab1{
        border-start-start-radius: 0 !important;
    }
    #tab1:checked ~ section .tab1,
    #tab2:checked ~ section .tab2,
    #tab3:checked ~ section .tab3 {
        display: block;
    }

    ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            label {
                color: $white;
                border: 1px solid transparent;
                padding: 15px 30px;
                border-bottom: 0;
                opacity: 0.3;
                border-radius: 1rem 1rem 0 0;
                @media only screen and (max-width: $break-xsmall) {
                    padding: 15px;
                }

                &:hover{
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }
    section {
        clear: both;

        > div {
            border: 1px dashed rgba($white, 0.2);
            padding: 20px;
            width: 100%;

            @media only screen and (max-width: $break-xsmall) {
                padding: 20px;
            }
        }
    }

    #tab1:checked ~ nav .tab1,
    #tab2:checked ~ nav .tab2,
    #tab3:checked ~ nav .tab3 {

        label {
            background: var(--primary-color);
            border: 1px dashed rgba($white, 0.2);
            position: relative;
            opacity: 1;

            &:after {
                background: var(--primary-color);
                content: '';
                display: block;
                position: absolute;
                height: 4px;
                width: 100%;
                left: 0;
                bottom: -2px;
                z-index: 1;
            }
        }
    }
}