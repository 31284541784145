// Admin Layout (AVIO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: AVIO
// Copyright 2022 WrrapTheme

// bootstrap class overwrite css
.border,
.border-top,
.border-right,
.border-end,
.border-bottom,
.border-left,
.border-start,
.dropdown-divider,
.input-group-text {
    border-color: var(--border-color) !important;

    &.border-primary{
        border-color: var(--primary-color) !important;
    }
    &.border-secondary{
        border-color: var(--secondary-color) !important;
    }
    &.border-success{
        border-color: $color-success !important;
    }
    &.border-danger{
        border-color: $color-danger !important;
    }
    &.border-warning{
        border-color: $color-warning !important;
    }
    &.border-info{
        border-color: $color-info !important;
    }
    &.border-dark{
        border-color: var(--color-900) !important;
    }
    &.border-white{
        border-color: var(--color-fff) !important;
    }
    &.border-muted{
        border-color: var(--color-400) !important;
    }
}

// form-control
.form-floating{
    .form-select,
    .form-control{
        border-radius: 0.25rem;
    }
    >label{
        font-size: 15px;    
    }
}
.form-control,
.form-select {
    border-color: var(--border-color);
    background-color: var(--card-color);
    color: var(--color-900);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 15px;

    &::placeholder{
        color: var(--color-400);
    }
    &:hover{
        border-color: rgba(var(--primary-rgb), .3);
    }
    &:focus{
        box-shadow: 0 0 10px rgba($dark, .15);
        background-color: var(--card-color);
        border-color: var(--primary-color);
        color: var(--primary-color);
    }

    &.form-control-lg{
        min-height: calc(1.5em + 1.8rem + 2px);
    }
    &:disabled {
        cursor: not-allowed;
    }
}

// form-control with Icon
.form-icon-group {
    display: block;
    .form-icon {
        top: 50%;
        transform: translateY(-50%);
        padding: .375rem .50rem;
        width: 35px;
        height: 35px;
        z-index: 3;
    }
    svg {
        fill: var(--color-600);
    }
    &.left-icon {
        .form-icon {
            left: 0;
            right: auto;
        }
        .form-control {
            padding-left: 2.05rem;
        }
    }
    &.right-icon {
        .form-icon {
            left: auto;
            right: 0;
        }
        .form-control {
            padding-right: 2.05rem;
        }
    }
    
}

// form Switches css
.form-check-input {
    background-color: var(--border-color);
    border-color: var(--border-color);
    -webkit-appearance: none;
    &:checked {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
    }
}

// bottom
.btn {
    font-size: $font-size;

    &.btn-primary {
        background-color: var(--primary-color);
        border-color: var(--primary-color)
    }

    &.btn-light-primary {
        background-color: rgba(var(--primary-rgb), 0.15);
        color: var(--primary-color);
        border-color: transparent;

        &:hover{
            background-color: var(--primary-color);
            color: $white;
        }
    }
    &.btn-light-secondary{
        background-color: rgba($color-secondary, .15);
        color: $color-secondary;
        border-color: transparent;

        &:hover{
            background-color: $color-secondary;
            color: $white;
        }
    }
    &.btn-light-success{
        background-color: rgba($color-success, .15);
        color: $color-success;
        border-color: transparent;

        &:hover{
            background-color: $color-success;
            color: $white;
        }
    }
    &.btn-light-danger{
        background-color: rgba($color-danger, .15);
        color: $color-danger;
        border-color: transparent;

        &:hover{
            background-color: $color-danger;
            color: $white;
        }
    }
    &.btn-light-warning{
        background-color: rgba($color-warning, .15);
        color: $color-warning;
        border-color: transparent;

        &:hover{
            background-color: $color-warning;
            color: $white;
        }
    }
    &.btn-light-info{
        background-color: rgba($color-info, .15);
        color: $color-info;
        border-color: transparent;

        &:hover{
            background-color: $color-info;
            color: $white;
        }
    }

    &.btn-outline-primary {
        color: var(--primary-color);
        border-color: var(--primary-color);

        &:hover{
            background-color: var(--primary-color);
            color: $white;
        }
    }
    &.btn-outline-secondary {
        border-color: var(--border-color)
    }
    &.btn-outline-success{
        color: $color-success;
        border-color: $color-success;
        &:hover{
            background-color: $color-success;
            color: $white;
        }
    }
    &.btn-outline-danger{
        color: $color-danger;
        border-color: $color-danger;
        &:hover{
            background-color: $color-danger;
            color: $white;
        }
    }
    &.btn-outline-info{
        color: $color-info;
        border-color: $color-info;
        &:hover{
            background-color: $color-info;
            color: $white;
        }
    }

    &.btn-white {
        background-color: var(--color-fff);
        border-color: var(--color-fff);
        color: var(--color-700);
    }
    &.btn-dark {
        background-color: var(--color-900);
        border-color: var(--color-900);
        color: var(--color-400);
    }

    &.btn-lg {
        padding: .89rem 1rem;
        font-size: 1rem;
    }
}
.btn-group{
    .btn-check:checked+.btn-outline-primary,
    .btn-check:active+.btn-outline-primary,
    .btn-outline-primary:active,
    .btn-outline-primary.active,
    .btn-outline-primary.dropdown-toggle.show{
        background-color: var(--primary-color);
        border-color: var(--primary-color);
    }
}
button {
    &:focus {
		&:not(:focus-visible) {
			box-shadow: none;
		}
	}
}
.accordion-button{
    background-color: var(--card-color);
    color: var(--color-600);
}
.btn-close{
    background-color: $white;
}

// avatar
.avatar {
    width: 34px;
    min-width: 34px;
    height: 34px;

    &.xs{
        width: 16px;
        min-width: 16px;
        height: 16px;
        font-size: 8px;
    }
    &.sm{
        width: 26px;
        min-width: 26px;
        height: 26px;
    }
    &.lg{
        width: 46px;
        min-width: 46px;
        height: 46px;
    }
    &.xl{
        width: 90px;
        min-width: 90px;
        height: 90px;
    }
    &.xxl{
        width: 120px;
        min-width: 120px;
        height: 120px;
    }
    &.no-thumbnail{
        background-color: rgba(var(--primary-rgb), 0.15);
        color: var(--primary-color);
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// dropdown menu
.more-icon{
    @include transition(ease .3s);
    background: rgba(var(--primary-rgb), 0.1);
    color: var(--primary-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 30px;
    
    &.show,
    &:hover{
        background: rgba(var(--primary-rgb), 1);
        color: $white;
    }
    &::after{
        display: none;
    }
    + .dropdown-menu {
        border-radius: .75rem;

        .dropdown-item:hover,
        .dropdown-item:focus,
        .dropdown-item.active{
            background-color: rgba(var(--primary-rgb), 0.15);
            color: var(--primary-color);
            border-radius: .2rem;
        }
    }
}

// dropdown menu
.dropdown-menu {
    font-size: $font-size + 1px;
    
    .dropdown-item{
        color: var(--color-500);
        &:hover,
        &:focus,
        &.active{
            background-color: rgba(var(--primary-rgb), 0.15);
            color: var(--primary-color);
        }
    }
    
    li.dropdown-submenu{
        position: relative;

		.dropdown-menu {
			top: 0 !important;
			left: 100% !important;
			margin-top: -1px !important;
            transform: translate(0, 0) !important;
            inset: 0px auto auto 0px;
		}

        @media (hover: hover) {
            &:hover > ul.dropdown-menu {
                display: block;
            }
        }
    }

    &.mega-dropdown{
        width: 600px;
        a{
            color: var(--color-500);
        }
        
        @media only screen and (max-width: $break-small) {
            width: 100%;
        }
    }

    &.datepicker{
        padding: .8rem;

        td,
        th{
            width: 28px;
            height: 28px;
        }
    }
}

.after-none::after{
    display: none;
}

// custom modal popup css
.modal {
    right: 0;
    left: auto;
    
    &.fade{
        .modal-dialog-vertical{
            transform: translateX(-100%);
        }
    }
    &.show{
        .modal-dialog-vertical{
            transform: translateX(0);

            &.right-side{
                right: 0;
            }
        }
    }
    .modal-header,
    .modal-footer{
        border-color: var(--border-color);
    }
    .modal-title{
        color: var(--color-800);
    }   
    .modal-content{
        background-color: var(--card-color);
        border-color: var(--border-color);
        color: var(--color-600);
        .dropdown-item{
            color: var(--color-500);

            &:hover{
                color: var(--primary-color);
                background-color: rgba(var(--primary-rgb), 0.1);
            }
        }
    }
    .modal-dialog-vertical{
        margin: 0;
        height: 100%;
        
        .modal-content{
            height: 100%;
            border: 0;
            border-radius: 0;
        }
        &.right-side{
            @include transition(ease-in-out .2s);
            transform: none;
            position: absolute;
            right: -300px;
            @media only screen and (max-width: $break-xsmall) {
                position: unset;
            }
        }
    }
}

// custom offcanvas popup css
.offcanvas {
    background-color: var(--card-color);
    .offcanvas-header,
    .offcanvas-footer{
        border-color: var(--border-color);
    }
    .offcanvas-title{
        color: var(--color-800);
    }
    .offcanvas-content{
        background-color: var(--card-color);
        color: var(--color-600);
        .dropdown-item{
            color: var(--color-500);

            &:hover{
                color: var(--primary-color);
                background-color: rgba(var(--primary-rgb), 0.1);
            }
        }
    }
}

// breadcrumb
.breadcrumb {
    background-color: var(--color-200);
}

// navbar  
.navbar-light {
    .navbar-nav {
        .nav-link {
            color: var(--color-500);
            &:hover,
            &:focus{
                color: var(--primary-color);
            }
        }
    }
}

// custom tab ui
.nav-tabs {
    border-color: var(--border-color);
    &.tab-custom{
        border: 1px solid rgba($white, .2);
        .nav-link{
            color: $white;
            opacity: 0.7;
            &.active{
                opacity: 1;
                color: var(--primary-color);
            }
        }
    }
    &.tab-card{
        padding: 0 1.5rem;
        @media only screen and (max-width: $break-small) {
            padding: 0 1rem;
        }
        .nav-link{
            color: var(--color-500);
            background-color: transparent;
            border-bottom: 7px solid transparent;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            margin-bottom: -4px;
            @media only screen and (max-width: $break-small) {
                padding-left: 14px;
                padding-right: 14px;
            }

            &.active,
            &:hover{
                border-color: var(--primary-color);
                color: var(--primary-color);
            }
        }
    }
    &.tab-page-toolbar{
        border: 1px solid Var(--primary-color);
        overflow: hidden;
        padding: 3px;
        .nav-link{
            border: 0;
            margin: 0;
            padding: .3rem 1rem;
            border-radius: .25rem;
            color: var(--color-500);

            &:hover{
                color: var(--primary-color);
            }

            &.active{
                background-color: var(--primary-color);
                color: $white;
            }
        }
    }
    &.tab-h-scroll{
        @include c_overflow(scroll);
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }
    &.menu-list{
        flex-direction: column;
        .nav-link{
            color: var(--color-500);
            display: flex;
            align-items: center;
            border-radius: .25rem;
            margin: 0;
            border: 0;
            padding: .6rem 0;

            &.active,
            &:hover{
                color: var(--primary-color);
                background-color: transparent;
                font-weight: bold;
            }

            i.fa{
                width: 26px;
            }
        }
    }
}

// nav pill
.nav-pills {
    .nav-link.active,
    .show>.nav-link{
        background-color: var(--primary-color);
    }
    .nav-link{
        color: var(--primary-color);
    }
    &.custom-pill{
        margin-left: -.25rem;
        .nav-link {
            position: relative;
            &.active,
            &:hover{
                &:after {
                    color: var(--primary-color);
                    content: '\f0d7';
                    font-family: 'FontAwesome';
                    position: absolute;
                    bottom: -18px;
                    left: 10px;
                    font-size: 20px;
                }
            }
        }
    }
    &.custom-horizontal{
        border-right: 1px solid var(--primary-color);
        flex-direction: column;
        text-align: right;
        .nav-link{
            border-right: 4px solid transparent;
            color: var(--color-700);
            border-radius: 0;
            &.active {
                color: var(--primary-color);
                border-color: var(--primary-color);
                background-color: transparent;
            }
        }
    }
}

// list-group design
.list-group-custom {
    .list-group-item{
        background-color: var(--card-color);
        border-color: var(--border-color);
        color: var(--color-500);
        border-style: dashed;
        padding: .65rem 1rem;

        &:hover,
        &.active{
            background-color: rgba(var(--primary-rgb), 0.1);
        }
    }
    &.sticky-top {
        z-index: 8;
    }
}
.list-group-item {
    background-color: var(--card-color);
    border-color: var(--border-color);
    color: var(--color-500);
}

// progress bar design
.progress {
    background-color: var(--color-200);
}

//alert
.alert{
    border-radius: 0.75rem;
    border-style: dashed;
}

// table 
.table {
    border-color: var(--border-color);
    tr{
        th{
            //border-color: var(--primary-color);
            color: var(--color-800);
            text-transform: uppercase;
            font-size: 12px;
        }
        td{
            border-color: var(--border-color);
            color: var(--color-500);
            padding: .8rem .6rem;
        }
        &:hover{
            td{
                color: var(--color-900);
            }
        }
    }
    &.table-striped{
        >tbody>tr:nth-of-type(odd)>*{
            color: var(--color-700);
        }
    }
    &.border-primary{
        tr th,
        tr td{
            border-color: var(--primary-color);
        }
    }
    &.table-sm{
        td{
            padding: .5rem .6rem;
        }
    }
    &.custom-table{
        border-collapse: separate !important;
        border-spacing: 0 5px !important;

        thead{
            tr{
                th{
                    border: 0;
                }
            }
        }
        tbody{
            tr{
                background: var(--card-color);
                &.cancle-row{
                     td{
                        //background: rgba($color-danger, .05);
                     }
                }
            }
            &:hover{
                tr{
                    @include transition(ease .3s);
                    opacity: .7;
                    &:hover{
                        opacity: 1;
                    }
                }
            }    
        }
        tr{
            td, th{
                vertical-align: middle;
                white-space: nowrap;
                padding-left: 1rem;
                padding-right: 1rem;
                border-right: 0;
                border-left: 1px solid var(--border-color);
                
                &:first-child{
                    border-radius: 0.25rem 0 0 0.25rem;
                    border-left: 0;
                }
                &:last-child{
                    border-radius: 0 0.25rem 0.25rem 0;
                }
            }
        }
    }
    &.custom-table-2{
        border-collapse: separate !important;
        border-spacing: 0 1px !important;

        thead{
            tr{
                th{
                    border: 0;
                }
            }
        }
        tbody{
            tr{
                background: var(--card-color);
                &.cancle-row{
                     td{
                        background: rgba($color-danger, .05);
                    }
                }
                &.active-row{
                     td{
                        background: rgba($color-info, .05);
                    }
                }
            }    
        }
        tr{
            td, th{
                vertical-align: middle;
                white-space: nowrap;
                padding-left: 1rem;
                padding-right: 1rem;
                border-right: 0;
                
                &:first-child{
                    border-radius: 0.25rem 0 0 0.25rem;
                    border-left: 0;
                }
                &:last-child{
                    border-radius: 0 0.25rem 0.25rem 0;
                }
            }
        }
    }
    &.card-table{

        thead{
            tr th{
                border-top: 1px dashed var(--border-color);
            }
        }
                
        tbody{
            border-top: none;
            tr{
                background: var(--card-color);
            }
            &:hover{
                tr{
                    @include transition(ease .3s);
                    opacity: .7;
                    &:hover{
                        background: var(--body-color);
                        opacity: 1;
                    }
                }
            }    
        }
        tr{
            td, th{
                vertical-align: middle;
                white-space: nowrap;
                padding-left: 1rem;
                padding-right: 1rem;
                border-right: 0;
                border-bottom: 1px dashed var(--border-color);
                border-left: 1px dashed var(--border-color);
                
                &:first-child{
                    border-radius: 0.25rem 0 0 0.25rem;
                    border-left: 0;
                }
                &:last-child{
                    border-radius: 0 0.25rem 0.25rem 0;
                }
            }
            &:last-child{
                td{
                    border-bottom: 0;
                }
            }
        }
    }
    &.accordion{
        border-collapse: separate !important;
        border-spacing: 0 1px !important;

        thead{
            tr{
                th{
                    border: 0;
                }
            }
        }
        tbody{
            tr{
                background: var(--card-color);
                &.cancle-row{
                     td{
                        background: rgba($color-danger, .05);
                    }
                }
                &.active-row{
                     td{
                        background: rgba($color-info, .05);
                    }
                }
            }    
        }
        tr{
            td, th{
                padding-left: 1rem;
                padding-right: 1rem;
                border-right: 0;
                
                &:first-child{
                    border-radius: 0.25rem 0 0 0.25rem;
                    border-left: 0;
                }
                &:last-child{
                    border-radius: 0 0.25rem 0.25rem 0;
                }
            }
        }
    }
}

// pagination
.page-item{

    &:first-child{
        .page-link{
            border-top-left-radius: 2rem;
            border-bottom-left-radius: 2rem;
        }
    }
    &:last-child {
        .page-link {
            border-top-right-radius: 2rem;
            border-bottom-right-radius: 2rem;
        }
    }

    .page-link{
        background-color: var(--card-color);
        border-color: var(--border-color);
        color: var(--color-600);
    }
    
    &.disabled{
        .page-link{
            background-color: var(--border-color);
            border-color: var(--border-color);
        }
    }
    &.active,
    &:hover{
        .page-link{
            background-color: var(--primary-color);
            border-color: var(--border-color);
            color: var(--color-fff);
        }
    }
}

.fc-custome{
    .btn{
        border: 1px dashed var(--border-color);
        &.active{
            border: 1px dashed var(--primary-color);
            background: rgba(var(--primary-rgb), 0.1);
            color: var(--primary-color);
        }
    }
}

// custome radio btn
.c_radio{

    > label {
        cursor: pointer;

        input[type="radio"],
        input[type="checkbox"] {
            position: absolute;
            opacity: 0;

            &:checked + .card {
                @include transition(ease-in .1s);
                border: 1px dashed var(--primary-color);
                background: rgba(var(--primary-rgb),.15);

                &:after {
                    background: var(--primary-color);
                    border: 3px solid var(--card-color);
                    box-shadow: 0px 0px 0px 2px var(--primary-color);
                    //content: "";
                    position: absolute;
                    height: 1rem;
                    width: 1rem;
                    right: 20px;
                    top: 20px;
                }
            }
        }

        input[type="radio"] {
            &:checked + .card {
                &:after {
                    border-radius: 100%;
                }
            }
        }

        .card {
            &:hover {
                border: 1px dashed var(--primary-color);
                background: rgba(var(--primary-rgb),.1);
            }
        }
    }
}

// tooltip
.tooltip {
    &.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before{
        border-top-color: var(--primary-color);
    }
    &.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before{
        border-right-color: var(--primary-color);
    }
    &.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before{
        border-bottom-color: var(--primary-color);
    }
    &.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before{
        border-left-color: var(--primary-color);
    }
    
    .tooltip-inner{
        background-color: var(--primary-color);
        color: $white;
    }
}

// badge
.badge{
    &.bg-warning{
        color: $dark;
    }
}


// img upload 
.file-input{
    input{
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    label:hover{
        cursor: pointer;
        color: var(--primary-color);
    }
}
.image-input{
    position: relative;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;

    .avatar-wrapper{
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 100%;
        height: 100%;
    }

    &.avatar{
        &.xxl{
            .file-input{
                position: absolute;
                bottom: 10px;
                right: 10px;
            }
        }
        &.xl{
            .file-input{
                position: absolute;
                bottom: 5px;
                right: 5px;
            }
        }
        &.lg{
            .file-input{
                position: absolute;
                bottom: -5px;
                right: -5px;
            }
        }
    }

    .file-input{
        label{
            background: var(--card-color);
            text-align: center;
            height: 24px;
            width: 24px;
            line-height: 24px;
            border-radius: 24px;
        }
    }
}