// Admin Layout (AVIO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: AVIO
// Copyright 2022 WrrapTheme

.table {
    &.myDataTable{
		width: 100% !important;
		.dtr-details{
			width: 100%;
			li{
				border-color: var(--border-color) !important;
			}
		}
		tr{
            td, th{
                
                &:first-child{
                    outline: none;
                }
            }
        }
	}
	&.table-lg{
		&.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>{
			td:first-child,
			th:first-child{
				&::before{
					top: 24px;
				}
			}
		}
	}

	&.dataTable{
		width: 100% !important;
		white-space: nowrap;
		.form-check{
			padding-top: 2px;
		}
		.dtr-details{
			margin-bottom: 0;
			li{
				padding: 5px 0;
			}
		}
		&.dtr-inline{
			&.collapsed{
				>tbody{
					>tr.odd,
					>tr.even{
						>td, >th{
							position: relative;
							&:first-child{
								padding-left: 36px;
								&::before{
									@include transition(ease .3s);
									background-color: rgba(var(--primary-rgb),0.3);
									font-family: 'FontAwesome';
									content: '\f105';
									text-align: center;
									position: absolute;
									border-radius: 20px;
									left: 5px;
									top: 50%;
									width: 20px;
									height: 20px;
									line-height: 20px;
									transform: translateY(-50%);
								}
							}
						}
					}
					>tr.parent{
						>td:first-child:before,
						>th:first-child:before{
							content: '\f107';
							background-color: var(--primary-color);
							color: $white;
						}
					}
				}
			}
		}
	}
}