// Admin Layout (AVIO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: AVIO
// Copyright 2022 WrrapTheme

.gallery_product{
    position:relative;

    .img-info{
        position: absolute;
        overflow:hidden;
        display:none;
        background: rgba(0,0,0,0.5);
        color:#fff;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
        top:0;
        
        -webkit-transition: 2s;
        transition: 2s;
    }
    &:hover {
        .img-info{
            display:block;
            -webkit-transition: 2s;
            transition: 2s;
        }
    }
}