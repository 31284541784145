// Admin Layout (AVIO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: AVIO
// Copyright 2022 WrrapTheme

.fc{
    * {
        color: var(--color-600);
    }
    .fc-col-header-cell-cushion{
        color: var(--color-600);
    }
    .fc-h-event .fc-event-title{
        color: var(--white-color);
    }
    .fc-icon{
        &.fc-icon-chevron-left,
        &.fc-icon-chevron-right{
            color: var(--white-color);
        }
    }

    @media only screen and (max-width: $break-medium - 1px) {
        .fc-toolbar-title{
            font-size: 1.2em;
        }
        .fc-button{
            padding: .1em .40em;
        }
    }
}

.fc-theme-standard{
    .fc-scrollgrid,
    td,
    th{
        border-color: var(--border-color);
    }
}