// Admin Layout (AVIO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: AVIO
// Copyright 2022 WrrapTheme

.jsgrid{
    .jsgrid-grid-header{
        background: var(--border-color);
        border-color: var(--border-color);
    }
    .jsgrid-button{
        background-image: url(../plugin/jsgrid/icons.png);
        border: none;
        cursor: pointer;
        background-repeat: no-repeat;
        background-color: transparent;
        width: 16px;
        height: 16px;
        margin: 0 5px;
        
        &.jsgrid-edit-button{
            background-position: 0 -120px;
        }
        &.jsgrid-delete-button{
            background-position: 0 -80px;
        }
        &.jsgrid-insert-mode-button{
            background-position: 0 -160px;
            width: 24px;
            height: 24px;
        }
        &.jsgrid-update-button{
            background-position: 0 -336px;
        }
        &.jsgrid-cancel-edit-button{
            background-position: 0 0;
        }
    }
    input,
    .jsgrid textarea,
    .jsgrid select,
    select{
        border: 1px solid var(--border-color);
        background-color: var(--card-color);
        color: var(--color-900);
        border-radius: 0.25rem;
        background-clip: padding-box;
        &:focus-visible{
            border-color: var(--primary-color);
            box-shadow: none;
            outline: none;
        }
    }
    select{
        text-align: left;
    }
}

.jsgrid-header-row{
    >.jsgrid-header-cell{
        background: var(--border-color);
    }
}

.jsgrid-edit-row>.jsgrid-cell,
.jsgrid-filter-row>.jsgrid-cell,
.jsgrid-grid-body,
.jsgrid-grid-header,
.jsgrid-header-row>.jsgrid-header-cell,
.jsgrid-insert-row>.jsgrid-cell{
    border-color: var(--border-color);
}
.jsgrid-row{
    >.jsgrid-cell{
        background: var(--card-color);
        border-color: var(--border-color);
    }
}
.jsgrid-alt-row{
    >.jsgrid-cell{
        background: var(--color-100);
        border-color: var(--border-color);
    }
}
.jsgrid-cell{
    padding: 0.8em;
}

[data-theme="high-contrast"] {
    .jsgrid{
        .jsgrid-grid-header{
            color: #000000;
        }
    }
}