// Admin Layout (AVIO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: AVIO
// Copyright 2022 WrrapTheme

:root {
	--dark-color: 		#262727;

	--color-fff:		#FFFFFF;
	--color-100:		#F0F0F0;
	--color-200:		#EEEEEE;
	--color-300:		#E0E0E0;
	--color-400:		#BDBDBD;
	--color-500:		#525252;
	--color-600:		#464545;
	--color-700:		#363535;
	--color-800:		#252525;
	--color-900:		#181818;
	--color-000:		#000000;

	--border-color:		#e9e6e1;
	--card-color: 		#ffffff;
	--body-color: 		#f8f6f2;
	--white-color: 		#ffffff;
	--sidebar-color:	#ffffff;

	--primary-color: 	#191A1C;
	--secondary-color: 	#837788;

	--chart-color1:	 	#191A1C;
	--chart-color2: 	#837788;
	--chart-color3: 	#374765;
	--chart-color4: 	#484261;
	--chart-color5: 	#717788;

	//--sidebar-img: 		url(../images/sidebar-bg/sidebar-1.jpg);
	--sidebar-img: 			url(https://www.wrraptheme.com/templates/avio/assets/img/sidebar-bg/sidebar-1.jpg);
}

@import "themes";				// theme color blue, indigo, cyan more...
@import "dark";					// theme dark version scss
@import "themes-dark";			// theme color dark version like. blue with dark blue
@import "high-contrast";		// theme high contrast verson 