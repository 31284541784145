// Admin Layout (AVIO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: AVIO
// Copyright 2022 WrrapTheme

@font-face{
    font-family:"summernote";
    src: url("../fonts/summernote.eot");
    src:url("../fonts/summernote.eot?#iefix") format("embedded-opentype"),
    url("../fonts/summernote.woff2") format("woff2"),
    url("../fonts/summernote.woff") format("woff"),
    url("../fonts/summernote.ttf") format("truetype")
}

.note-editor{
    &.note-airframe,
    &.note-frame{
        background: var(--card-color);
        border: 1px solid var(--border-color);
        border-radius: .25rem;
    }
    .note-btn{
        color: var(--color-600);
        .note-icon-caret{
            display: none;
        }
    }
    .note-dropdown-menu{
        box-shadow: 0 0 10px rgba($dark, .15);
        top: 100%;
        a{
            color: var(--color-600);
        }
        &.dropdown-style{
            min-width: 210px;
            padding: 1rem;
        }        
    }
}
