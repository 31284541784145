// Admin Layout (AVIO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: AVIO
// Copyright 2022 WrrapTheme

.noUi-target{
    background: var(--color-200);
    border-color: var(--border-color);
    box-shadow: none;
    
    .noUi-connect{
        background: var(--primary-color);
    }
}